import { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/header/Header";
import BigBack from "./components/BigBack/BigBack.module";
import Card from "./components/Card/Card.module";
import Vids from "./components/Vids/Vids.module";
import HowBuy from "./components/HowBuy/HowBuy.module";
import Redpaper from "./components/Redpaper/Redpaper.module";

function App() {
  useEffect(() => {
    const videos = document.querySelectorAll("video");

    // Pause all <video> elements except for the one that started playing.
    function pauseOtherVideos({ target }) {
      for (const video of videos) {
        if (video !== target) {
          video.pause();
        }
      }
    }

    // Listen for the 'play' event on all the <video> elements.
    for (const video of videos) {
      video.addEventListener("play", pauseOtherVideos);
    }
  });
  return (
    <>
      <Header />
      <BigBack />
      <div className="app-container">
        <Card />
        <Vids />
        <Redpaper />
        <HowBuy />
        <div className="copyright">
          $JIN has got nothing to do with Xi Jinping.
          <br />© 2024 by Shi Jinpeng $JIN. All rights reserved.
        </div>
      </div>
    </>
  );
}

export default App;
