import React from "react";
import styles from "./Vids.module.css";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import jinint from "./../../videos/jinint.mp4";
import jinint2 from "./../../videos/jinint2.mp4";
import jinplane from "./../../videos/jinplane.mp4";

import jinintthumb from "./../../vidthumbs/jinintthumb.png";

import jinintthumb2 from "./../../vidthumbs/thumbint2.png";

import pissing from "./../../images/Pissing.jpg";
import angry from "./../../images/jinangry.jpg";
import helic from "./../../images/jinhelic.jpg";
import jinputin from "./../../images/jinputin.jpg";
import jinx from "./../../images/jinx.jpeg";

const jinvid = [
  jinintthumb,
  jinintthumb2,
  pissing,
  angry,
  helic,
  jinputin,
  jinx,
];

const Vids = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          //   backgroundColor: "#ddd",
          borderRadius: "10px",
          marginBottom: "-80px",
          //   padding: "10px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className={styles.jinth}
        style={{
          width: "80px",
          //   color: "blue",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          overflow: "hidden",
          boxShadow: "black 0px 0px 8px -5px",
        }}
      >
        <img src={jinvid[i]} />
      </div>
    ),
  };
  return (
    <div className={styles.videos}>
      <Slider {...settings}>
        <div className={styles.eachvideo}>
          <video width="100%" poster={jinintthumb} controls>
            <source src={jinint} type="video/mp4" />
          </video>
        </div>
        <div className={styles.eachvideo}>
          <video width="100%" poster={jinintthumb2} controls>
            <source src={jinint2} type="video/mp4" />
          </video>
        </div>
        <div className={styles.eachvideo}>
          <img src={pissing} />
        </div>
        <div className={styles.eachvideo}>
          <img src={angry} />
        </div>
        <div className={styles.eachvideo}>
          <img src={helic} />
        </div>
        <div className={styles.eachvideo}>
          <img src={jinputin} />
        </div>
        <div className={styles.eachvideo}>
          <img src={jinx} />
        </div>
      </Slider>
    </div>
  );
};

export default Vids;
