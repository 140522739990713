import React, { useState, useRef, useEffect } from "react";
import styles from "./Eye.module.css";
import jin from "./../../images/jinbcre.png";
const Eye = () => {
  const eyes = useRef([]);

  const eye = (el) => eyes.current.push(el);
  const pupils = eyes.current;
  useEffect(() => {
    window.addEventListener("mousemove", (e) => {
      pupils.forEach((pupil) => {
        // get x and y position of cursor
        if (!pupil) return;
        var rect = pupil.getBoundingClientRect();

        var x = (e.screenX - rect.left) / 300 + "px";
        var y = (e.screenY - rect.top) / 300 + "px";
        pupil.style.transform = "translate3d(" + x + "," + y + ", 0px)";
      });
    });
  }, []);
  return (
    <div className={styles.jineye}>
      <img className={styles.image} src={jin} />
      <div className={styles.eyes}>
        <div className={styles.eyeone}>
          <div className={styles.pupil} ref={eye}></div>
        </div>
        <div className={styles.eyetwo}>
          <div className={styles.pupil} ref={eye}></div>
        </div>
      </div>
    </div>
  );
};

export default Eye;
