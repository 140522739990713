import React from "react";
import styles from "./Redpaper.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const Redpaper = () => {
  return (
    <div className={styles.partred}>
      <Link to="JINWhitepaper.pdf" target="_blank" download>
        <div className={styles.buttonred}>
          Download RedPaper <FontAwesomeIcon icon={faDownload} />
        </div>
      </Link>
    </div>
  );
};

export default Redpaper;
