import React from "react";
import styles from "./Header.module.css";
import jinpeng from "./../../images/jin02.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  return (
    <header>
      <div className={`app-container ${styles.header}`}>
        <div className={styles.lefticon}>
          <img src={jinpeng} />
          $JIN
        </div>
        <div className={styles.rightbox}>
          <a href="https://dexscreener.com/solana/7ax1wvcorr192g5cjs3pvdqkbjfqesecir874424akpr">
            <div className={styles.buynow}>
              Buy Now
              <FontAwesomeIcon icon={faMoneyBillTransfer} />
            </div>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
